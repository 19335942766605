

header.header.fixed.main-menu__item .main-menu__link--highlighted,
header.header.fixed .main-menu__item--active .main-menu__link,
header.header.fixed .main-menu__item:hover .main-menu__link {
    color: #282a43;
    opacity: 0.75;
}


header.header.fixed .main-menu__item .main-menu__link{
    color: #282a43;
    opacity: 0.75;
}

.header--front_2 .main-menu__item--has-child .main-menu__link span::after {
    background: #282a43;
}

.header--front_2 .main-menu__item--active .main-menu__link span::after,
.header--front_2 .main-menu__item--has-child:hover span::after {
    background: #282a43;
    opacity: 0.75;
}

@media only screen and (min-width: 992px) {
    .header--front_2 .lang-select__item--active {
        color: #282a43;
        padding: 31px 20px 30px 20px;
    }
}

.header--front_2 .lang-select__item--active span::after,
.header--front_2 .lang-select__item--active span::before{
    background: #282a43;
}

.header--front_2 .lang-select__item--active::before {
    bottom: 40px;
}

header.header--front_2 {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    max-width: 1536px;
    left: auto;
    right: 0;
    position: absolute;
}

header.header.fixed {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255);
    box-shadow: 0px 4px 33px rgb(0 0 0 / 7%);
}

header.header.fixed .main-menu__item--has-child .main-menu__link span {
    /* font-weight: 400; */
}

.header--intro {
    min-height: 60px;
}

@media only screen and (min-width: 992px) {
    .header--intro {
        padding-left: 35px;
    }
}

header.header--intro .button {
    width: 200px;
    min-height: 70px;
}

@media only screen and (min-width: 1200px) {
    header.header--intro .button {
        width: 260px;
    }
}

.button--squared {
    height: 80%;
}
