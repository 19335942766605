.hexagon {
    overflow: hidden;
    visibility: hidden;

    -webkit-transform: rotate(120deg);
    -moz-transform: rotate(120deg);
    -o-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
    cursor: pointer;
}

.hexagon2 {
    width: 200px;
    height: 200px;
}

.gallery-item .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    height: 100%;
    display: block;
    -webkit-transition: all 50ms ease-in-out;
    -moz-transition: all 50ms ease-in-out;
    -ms-transition: all 50ms ease-in-out;
    -o-transition: all 50ms ease-in-out;
    transition: all 50ms ease-in-out;
}
.gallery-item .overlay a {
    color: #fff;
    width: 32px;
    height: 32px;
    background-color: #F05D24;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
    line-height: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -16px;
    margin-left: -16px;
}
.hexagon-in1 {
    overflow: hidden;
    width: 100%;
    height: 100%;

    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    transform: rotate(-60deg);
}

.hexagon-in2 {
    /*-webkit-box-shadow: inset 0 0 0 200px rgba(176, 218, 212, 0.48);
	box-shadow: inset 0 0 0 200px rgba(176, 218, 212, 0.48);*/
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    visibility: visible;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    transform: rotate(-60deg);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.hexagon-in2:hover {
    webkit-box-shadow: inset 0 0 0 200px rgba(94, 88, 115, 0.71);
    box-shadow: inset 0 0 0 200px rgba(94, 88, 115, 0.71);
}

.hexagon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
}
.hex-title {
    font-size: medium;
    color: #F05D24;
    font-weight: bold;
    margin-top: 10px;
    text-align: center;
}


.fslightbox-container {
    padding-top: 80px !important;
}

.fslightbox-absoluted {
    position: inherit !important;
    top: auto !important;
    left: auto !important;
}

.fslightbox-container {
    background: linear-gradient(rgba(94, 88, 115, 0.71),#000 1810%) !important;
}
